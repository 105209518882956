var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"favorites-selector"},[_c('div',{staticClass:"title titleContainer"},[_c('div',[_vm._v(" "+_vm._s('Events')+" ")]),_c('div',{staticClass:"titleChevron",on:{"click":_vm.forDropdown}},[_c('ChevronIcon',{attrs:{"width":24,"height":24,"rotation":_vm.rotation}})],1)]),_c('section',{class:{
      'main-favorite-container-none': !_vm.dropdown,
      'main-favorite-container': _vm.dropdown,
    }},[_c('div',{staticClass:"main-favorite-selector"},[_c('div',{class:{
          'selected-list p-1': _vm.returnUrl === 'live',
          'main-list': _vm.returnUrl !== 'live',
        },on:{"click":function($event){return _vm.routerChange('live')}}},[_c('LiveIcon',{attrs:{"color":_vm.returnUrl === 'live'
              ? 'white'
              : 'var(--settings-registration-current-event-button-background)'}}),_c('div',{class:{
            'selected-list-text p-1': _vm.returnUrl === 'live',
            'main-list-text': _vm.returnUrl !== 'live',
          }},[_vm._v(" "+_vm._s('Live')+" ")])],1),_c('div',{class:{
          'selected-list p-1': _vm.returnUrl === 'upcoming',
          'main-list': _vm.returnUrl !== 'upcoming',
        },on:{"click":function($event){return _vm.routerChange('upcoming')}}},[_c('UpcomingIcon',{attrs:{"color":_vm.returnUrl === 'upcoming'
              ? 'white'
              : 'var(--settings-registration-current-event-button-background)'}}),_c('div',{class:{
            'selected-list-text p-1': _vm.returnUrl === 'upcoming',
            'main-list-text': _vm.returnUrl !== 'upcoming',
          }},[_vm._v(" "+_vm._s('Upcoming')+" ")])],1),_c('div',{class:{
          'selected-list p-1': _vm.returnUrl === 'vod',
          'main-list': _vm.returnUrl !== 'vod',
        },on:{"click":function($event){return _vm.routerChange('vod')}}},[_c('OnDemandIcon',{attrs:{"color":_vm.returnUrl === 'vod'
              ? 'white'
              : 'var(--settings-registration-current-event-button-background)'}}),_c('div',{class:{
            'selected-list-text p-1': _vm.returnUrl === 'vod',
            'main-list-text': _vm.returnUrl !== 'vod',
          }},[_vm._v(" "+_vm._s('OnDemand')+" ")])],1)]),(_vm.isLogged)?_c('div',{staticClass:"list-favorite-selector"},[_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s('Favorites')+" ")]),_vm._l((_vm.favorites),function(favorite,index){return _c('div',{key:favorite.id},[_c('SideBarUpcomingListFavorite',{attrs:{"favorite":favorite,"position":index},on:{"invokedInput":function($event){return _vm.invokedInput(favorite)}}})],1)})],2):_c('div',[_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s('Favorites')+" ")]),_c('div',{staticClass:"subText"},[_vm._v(" "+_vm._s('Please log in to start favoriting')+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }